.contact-section {
  padding: 5.5rem 0 1rem 0;
}
.contact-card-wrapper {
  max-width: 1100px;
  margin: 0 auto;
  padding-top: 2rem;
}
.contact-card {
  background: #1f2235;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
  padding: 3rem 1rem;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  height: 100%;
}
.contact-icon-wrapper {
  width: 60px;
  margin: 0 auto;
  border: 1px solid white;
  border-radius: 50%;
  height: 60px;
  padding: 0.8rem;
}
.contact-icon-wrapper img {
  width: 100%;
}
.contact-card h1 {
  color: hsla(0, 0%, 100%, 0.699);
  margin: 0;
  font-size: 1.3rem;
  padding-top: 0.5rem;
  font-weight: 600;
  letter-spacing: 1px;
  text-align: center;
}
.contact-card p {
  margin: 0;
  color: white;
  font-size: 1rem;
  text-align: center;
}
.contact-form {
  padding-top: 3rem;
  max-width: 600px;
  margin: 0 auto;
}
.contact-form h1 {
  color: white;
  font-size: 1rem;
  text-align: center;
}
.contact-form p {
  color: hsla(0, 0%, 100%, 0.801);
  font-size: 1rem;
  text-align: center;
}
.contact-form form {
  padding-top: 1rem;
}
.form-field input {
  background-color: #272a3f;
  border: none;
  height: 55px;
  border-radius: 10px;
  color: white;
  box-shadow: none !important;
  outline: none;
}
.form-field textarea {
  background-color: #272a3f;
  border: none;
  height: 100px;
  border-radius: 10px;
  resize: none;
  color: white;
  box-shadow: none !important;
  outline: none;
}
.form-field input::placeholder,
.form-field textarea::placeholder {
  color: #5c646f;
}
.form-field button {
  border: 2px solid #ff4a57;
  background-color: #1f2235;
  border-radius: 30px;
  padding: 0.5rem 2rem;
  color: white;
  transition: all 0.5s;
}
.form-field button:hover {
  background-color: #ff4a57;
}
.contact-footer {
  max-width: 600px;
  margin: 0 auto;
  padding-top: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.social-wrappper {
  display: flex;
  gap: 1.5rem;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.social-wrappper a {
  width: 25px;
}
.social-wrappper a img {
  width: 100%;
}
.copyright p {
  color: white;
  text-align: center;
  font-size: 1rem;
}
.copyright p a {
  color: #ff4a57;
}
